@import "../components/App.scss";

article.idiom {
  .page-header {
    padding-top: 0;
  }
  .ant-page-header-content {
    padding-top: 0px;
  }
  .ant-page-header-content-view {
    padding-top: 0px;
  }

  .description .markdown {
    font-size: 0.95rem;
    word-break: break-word;

    h1,
    h2,
    h3,
    h4,
    h5 {
      color: $secondaryColor1;
    }
    h1 {
      font-size: 0.95rem;
    }
    h2 {
      font-size: 0.8rem;
    }
    h3 {
      font-size: 0.7rem;
    }
    h4 {
      font-size: 0.6rem;
    }
    h5 {
      font-size: 0.6rem;
    }
  }

  h3 {
    margin-top: 6px;

    span.anticon {
      font-size: 19px;
      vertical-align: middle;
    }
    span.anticon-copy,
    span.anticon-edit {
      color: #ccc;

      &:hover {
        color: #40a9ff;
      }
    }
  }

  .idiomTitle {
    &::first-letter {
      text-transform: uppercase;
    }
  }
  h2,
  h3,
  h4 {
    color: $primaryColor1;
    white-space: normal;
  }

  h4 {
    font-size: 1.1rem;
  }

  .content {
    color: #000;
  }

  .flagAvatarContainer {
    margin-bottom: 1rem;
  }

  .deleteIdiomButton {
    color: red;

    &:hover {
      border-color: red;
    }
  }

  .ant-tabs-tabpane-active.worldMapPanel {
    min-height: 550px;
  }
}
