@import "App.scss";

.ant-menu-item {
  .profileImage {
    margin-right: 10px;
  }
}

.ant-layout-header {
  .navCommandBar {
    border-bottom: none;
    align-self: flex-start;
    width: 100%;
    line-height: 46px;
  }

  .ant-menu-item {
    .ant-btn {
      padding-left: 0;
      padding-right: 0;

      & > .anticon + span {
        margin-left: 0;
      }
    }
  }
}
