.idiom {
  .equivalentList {
    padding: 0;

    .idiomListItem {
      padding-top: 5px;
      padding-bottom: 5px;
      padding-left: 0px;
      .itemHeader {
        font-size: 0.8rem;
      }

      .flagAvatarContainer {
        margin-bottom: 0px;
      }

      .idiomListTitle {
        font-size: 1rem;
        display: block;

      &::first-letter {
        text-transform: uppercase;
      }
      }

      .ant-list-item-meta-description {
        font-size: 0.85rem;
      }
    }
    &.ant-list-something-after-last-item
      .ant-spin-container
      > .ant-list-items
      > .ant-list-item:last-child {
      border-bottom: 0px;
    }
    .removeEquivalentButton {
      color: red;
      padding-bottom: 10px;
    }
  }
}
