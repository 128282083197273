@import "../components/App.scss";

.idiomListView {
  .idiomListItem {
    padding-left: 0;
    padding-right: 0;
  }

  .idiomListDetails {
    align-items: center;

    h4 {
      font-weight: bold;
      margin-bottom: 0;
    }

    .ant-list-item-meta-description {
      font-size: 0.9rem;
    }

    .ant-list-item-meta-avatar {
      flex-basis: 80px;
    }

    .itemHeader {
      font-size: 0.8rem;
      font-weight: normal;
      margin-bottom: 2px;
      display: flex;
      justify-content: space-between;
    }

    .idiomListTitle {
      font-size: 1.1rem;
      color: rgba(0, 0, 0, 0.65);
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
      display: block;

      &::first-letter {
        text-transform: uppercase;
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .idiomListView {
    .idiomListItem {
      flex-direction: column;
      align-items: initial;
      .equivalentCount {
        align-self: flex-end;
      }
    }
  }
}
